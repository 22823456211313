import useAxios from '../../utils/useAxios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getBonuses = createAsyncThunk('bonuses/get', async (...args) => {
  try {
    const axios = useAxios()
    const { data } = await axios.get('/api/bonuses/')
    return data
  } catch (error) {
    const { rejectWithValue } = args[1]
    // return custom error message from API if any
    if (error.response && error.response.data.detail) {
      return rejectWithValue(error.response.data.detail)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const saveBonus = createAsyncThunk('bonus/save', async (bonus, { rejectWithValue }) => {
  try {
    const axios = useAxios()
    const { data } = await axios.put(`/api/bonuses/${bonus.id}/`, bonus)
    return data
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.detail) {
      return rejectWithValue(error.response.data.detail)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const createBonus = createAsyncThunk('bonus/create', async (bonus, { rejectWithValue }) => {
  try {
    const axios = useAxios()
    const { data } = await axios.post('/api/bonuses/', bonus)
    return data
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.detail) {
      return rejectWithValue(error.response.data.detail)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const deleteBonus = createAsyncThunk('bonus/delete', async (bonus, { rejectWithValue }) => {
  try {
    const axios = useAxios()
    const { data } = await axios.delete(`/api/bonuses/${bonus.id}/`)
    return data
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.detail) {
      return rejectWithValue(error.response.data.detail)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const deleteBonusPayment = createAsyncThunk(
  'bonuspayment/delete',
  async (bonusPayment, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const { data } = await axios.delete(`/api/bonuspayments/${bonusPayment.id}/`)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.detail) {
        return rejectWithValue(error.response.data.detail)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)
