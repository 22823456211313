import useAxios from '../../utils/useAxios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getCostItems = createAsyncThunk('costitems/get', async (...args) => {
  try {
    const axios = useAxios()
    const { data } = await axios.get('/api/costitems')
    return data
  } catch (error) {
    const { rejectWithValue } = args[1]
    // return custom error message from API if any
    if (error.response && error.response.data.detail) {
      return rejectWithValue(error.response.data.detail)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const saveCostItem = createAsyncThunk(
  'costitem/save',
  async (costitem, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const { data } = await axios.put(`/api/costitems/${costitem.id}/`, costitem)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.detail) {
        return rejectWithValue(error.response.data.detail)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const createCostItem = createAsyncThunk(
  'costitem/create',
  async (costitem, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const { data } = await axios.post('/api/costitems/', costitem)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.detail) {
        return rejectWithValue(error.response.data.detail)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const deleteCostItem = createAsyncThunk(
  'costitem/delete',
  async (costitem, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const { data } = await axios.delete(`/api/costitems/${costitem.id}/`)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.detail) {
        return rejectWithValue(error.response.data.detail)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)
