import useAxios from '../../utils/useAxios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getTaskComments = createAsyncThunk('taskcomments/get', async (...args) => {
  try {
    const axios = useAxios()
    const { data } = await axios.get('/api/taskcomments/')
    return data
  } catch (error) {
    const { rejectWithValue } = args[1]
    // return custom error message from API if any
    if (error.response && error.response.data.detail) {
      return rejectWithValue(error.response.data.detail)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const saveTaskComment = createAsyncThunk(
  'taskcomment/save',
  async (tc, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const { data } = await axios.put(`/api/taskcomments/${tc.id}/`, tc)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.detail) {
        return rejectWithValue(error.response.data.detail)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const createTaskComment = createAsyncThunk(
  'taskcomment/create',
  async (tc, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const { data } = await axios.post('/api/taskcomments/', tc)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.detail) {
        return rejectWithValue(error.response.data.detail)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const deleteTaskComment = createAsyncThunk(
  'taskcomment/delete',
  async (tc, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const { data } = await axios.delete(`/api/taskcomments/${tc.id}/`)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.detail) {
        return rejectWithValue(error.response.data.detail)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)
