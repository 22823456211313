import useAxios from '../../utils/useAxios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getPaymentConditions = createAsyncThunk('paymentConditions/get', async (...args) => {
  try {
    const axios = useAxios()
    const { data } = await axios.get('/api/paymentconditions/')
    return data
  } catch (error) {
    const { rejectWithValue } = args[1]
    // return custom error message from API if any
    if (error.response && error.response.data.detail) {
      return rejectWithValue(error.response.data.detail)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const getPaymentCondition = createAsyncThunk(
  'paymentCondition/get',
  async (paymentConditionId, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const { data } = await axios.get(`/api/paymentconditions/${paymentConditionId}/`)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.detail) {
        return rejectWithValue(error.response.data.detail)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const getPaymentConditionByProject = createAsyncThunk(
  'paymentConditionByProject/get',
  async (projectId, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const { data } = await axios.get(`/api/projects/${projectId}/paymentconditions/`)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.detail) {
        return rejectWithValue(error.response.data.detail)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const savePaymentCondition = createAsyncThunk(
  'paymentcondition/save',
  async (paymentcondition, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const { data } = await axios.put(
        `/api/paymentconditions/${paymentcondition.id}/`,
        paymentcondition,
      )
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.detail) {
        return rejectWithValue(error.response.data.detail)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const createPaymentCondition = createAsyncThunk(
  'paymentcondition/create',
  async (paymentcondition, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const { data } = await axios.post('/api/paymentconditions/', paymentcondition)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.detail) {
        return rejectWithValue(error.response.data.detail)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)
