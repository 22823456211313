import useAxios from '../../utils/useAxios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const saveAttachment = createAsyncThunk(
  'attachment/save',
  async (attachment, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const { data } = await axios.put(`/api/attachments/${attachment.id}/`, attachment)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.detail) {
        return rejectWithValue(error.response.data.detail)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const createAttachment = createAsyncThunk(
  'attachment/create',
  async (attachment, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const config = { headers: { 'content-type': 'multipart/form-data' } }
      const { data } = await axios.post('/api/attachments/', attachment, config)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.detail) {
        return rejectWithValue(error.response.data.detail)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const deleteAttachment = createAsyncThunk(
  'attachment/delete',
  async (attachment, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const { data } = await axios.delete(`/api/attachments/${attachment.id}/`)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.detail) {
        return rejectWithValue(error.response.data.detail)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const getAttachments = createAsyncThunk('attachments/get', async (...args) => {
  try {
    const axios = useAxios()
    const { data } = await axios.get(`/api/attachments/`)
    return data
  } catch (error) {
    const { rejectWithValue } = args[1]
    // return custom error message from API if any
    if (error.response && error.response.data.detail) {
      return rejectWithValue(error.response.data.detail)
    } else {
      return rejectWithValue(error.message)
    }
  }
})
